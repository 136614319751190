import React, { useEffect, Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import Spacing from '../components/partials/Spacing'

import playtika_white_logo from '../resources/images/clients/playtika/playtika_white_logo.png'
import playtika_bg from '../resources/images/clients/playtika/playtika_bg.jpg'

import marine_traffic_white_logo from '../resources/images/clients/marine_traffic/marine_traffic_white_logo.png'
import marine_traffic_bg from '../resources/images/clients/marine_traffic/marine_traffic_bg.jpg'

import vortexa_white_logo from '../resources/images/clients/vortexa/vortexa_white_logo.png'
import vortexa_bg from '../resources/images/clients/vortexa/vortexa_bg.jpg'

import viseca_white_logo from '../resources/images/clients/viseca/viseca_white_logo.png'
import viseca_bg from '../resources/images/clients/viseca/viseca_bg.png'

import article_white_logo from '../resources/images/case_studies/article/article_white_logo.svg'
import article_bg from '../resources/images/case_studies/article/article_bg.jpeg'

import avanza_white_logo from '../resources/images/clients/avanza/avanza_white_logo.png'
import avanza_bg from '../resources/images/clients/avanza/avanza_bg.jpg'

import babylon_white_logo from '../resources/images/clients/babylon/babylon_white_logo.png'
import babylon_bg from '../resources/images/clients/babylon/babylon_bg.png'

import nuvo_white_logo from '../resources/images/clients/nuvo/nuvo-white.svg'
import nuvo_bg from '../resources/images/clients/nuvo/featured-nuvo.png'

import { initializeVideos } from '../helpers/initializeVideo'
import { navbarScrolling } from '../helpers/navbarScrolling'
import AboveFooterSection from '../components/AboveFooterSection'

const whatIsApacheKafka = () => {
  const data = useStaticQuery(graphql`
    query What_Is_Apache_Kafka {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "WhatIsApacheKafka" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    try {
      navbarScrolling()
      initializeVideos()
    } catch (err) {
      console.log(err)
    }
    // es-lint-disable-next-line
  }, [])

  const pageSEO = data.allContentfulPages.edges[0].node

  const customerList = [
    {
      node: {
        backgroundImage: article_bg,
        imageWhiteLogo: article_white_logo,
        slug: '/customers/article-wins-marketshare-using-apache-kafka/',
        title: 'How Article is winning market share using Apache Kafka',
      },
    },
    {
      node: {
        backgroundImage: nuvo_bg,
        imageWhiteLogo: nuvo_white_logo,
        slug: '/customers/how-nuvo-processes-real-time-data-with-apache-kafka/',
        title: 'Accessing and processing real-time heart rate data',
      },
    },
    {
      node: {
        backgroundImage: avanza_bg,
        imageWhiteLogo: avanza_white_logo,
        slug: '/customers/avanza/',
        title: 'How developers democratize data across all their Kafka Streams',
      },
    },
    {
      node: {
        backgroundImage: babylon_bg,
        imageWhiteLogo: babylon_white_logo,
        slug: '/videos/babylon-health-dataops-apache-kafka/',
        title:
          'Accelerating data engineering to improve the health of millions',
      },
    },
    {
      node: {
        backgroundImage: marine_traffic_bg,
        imageWhiteLogo: marine_traffic_white_logo,
        slug: '/customers/marine-traffic/',
        title: 'How MarineTraffic tracks shipping fleet data with Apache Kafka',
      },
    },
    {
      node: {
        backgroundImage: playtika_bg,
        imageWhiteLogo: playtika_white_logo,
        slug: '/customers/playtika/',
        title: 'How Playtika takes DataOps to production with Lenses',
      },
    },
    {
      node: {
        backgroundImage: vortexa_bg,
        imageWhiteLogo: vortexa_white_logo,
        slug: '/customers/vortexa/',
        title: 'How Lenses makes Kafka smooth sailing at Vortexa',
      },
    },
    {
      node: {
        backgroundImage: viseca_bg,
        imageWhiteLogo: viseca_white_logo,
        slug: '/customers/viseca/',
        title: 'A Streaming Data Platform for real-time customer interactions',
      },
    },
  ]

  return (
    <Fragment>
      <Layout linkColor={'dark'} isTransparent={true}>
        <HeaderSEO pageSEO={pageSEO} />
        <section className="hero-margin ">
          <div className="container-1 pb-5">
            <div className="d-flex flex-wrap mt-3">
              <div className="col-md-6 pt-0">
                <h1 className="w-75">What is Apache Kafka?</h1>
                <div className="pr-5 pt-3 pb-2">
                  <p className="hero-description-dark">
                    Apache Kafka is a distributed messaging system used for
                    stream processing, and is used by thousands of organizations
                    to power real-time applications.
                  </p>
                </div>
                <p className="hero-description-dark">
                  Let&apos;s look at the benefits of Apache Kafka, how it
                  differs from other related data technologies, how it works and
                  how it&apos;s used.
                </p>
              </div>
              <div className="col-md-6">
                <div className="cloud-aws-msk-video">
                  <iframe
                    src=""
                    data-src="//player.vimeo.com/video/589238239"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Spacing />

        <section>
          <div className="container-1">
            <div className="text-center">
              <h2>Why use Apache Kafka?</h2>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <div className="col-md-10 col-12 text-center">
                <p>
                  Kafka helps to modernize business processes by providing the
                  infrastructure to deliver real-time data and timely
                  experiences to consumers - with use cases ranging from
                  reducing mainframe costs and order fulfilment to fraud
                  detection and risk calculation.
                </p>
              </div>
            </div>

            <div className="d-flex flex-wrap">
              <div className="col-md col-sm-6 col-12 mt-1">
                <div className="border-top-0 rounded-1">
                  <StaticImage
                    className="w-100 "
                    src="../resources/images/apache_kafka_for_finance/data-integration.svg"
                    placeholder="tracedSVG"
                    alt="Data integration"
                  />
                  <div className="pb-4 px-3">
                    <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                      Data integration
                    </h3>
                    <ul className="red-checked-list">
                      <li>
                        <span>Reducing mainframe costs</span>
                      </li>
                      <li>
                        <span>Integrating market data</span>
                      </li>
                      <li>
                        <span>Security analytics pipelines</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md col-sm-6 col-12 mt-1">
                <div className="border-top-0 rounded-1">
                  <StaticImage
                    className="w-100 "
                    src="../resources/images/apache_kafka_for_finance/data-products.svg"
                    placeholder="tracedSVG"
                    alt="AI & data products"
                  />
                  <div className="pb-4 px-3">
                    <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                      AI & data products
                    </h3>
                    <ul className="red-checked-list">
                      <li>
                        <span>Risk calculation engine</span>
                      </li>
                      <li>
                        <span>Payment fraud detection</span>
                      </li>
                      <li>
                        <span>Churn prediction</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md col-sm-6 col-12 mt-1">
                <div className="border-top-0 rounded-1">
                  <StaticImage
                    className="w-100 "
                    src="../resources/images/apache_kafka_for_finance/applications.svg"
                    placeholder="tracedSVG"
                    alt="Software applications"
                  />
                  <div className="pb-4 px-3">
                    <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                      Software applications
                    </h3>
                    <ul className="red-checked-list">
                      <li>
                        <span>Mobile banking</span>
                      </li>
                      <li>
                        <span>Financial roboadvisor chatbot</span>
                      </li>
                      <li>
                        <span>Digital wallet</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Spacing />
        <section>
          <div className="container-1 mobile-margin-top">
            <div className="text-center">
              <h2>Apache Kafka vs. RabbitMQ</h2>
            </div>
            <table className="table table-responsive table-pricing">
              <thead>
                <tr className="border-pricing-team ">
                  <th
                    className="border-right border-pricing-team pt-4 pl-4 footer-color "
                    scope="col"
                  >
                    Characteristic
                  </th>
                  <th
                    className="border-right border-pricing-team pt-4 text-center footer-color "
                    scope="col"
                  >
                    Apache Kafka
                  </th>
                  <th
                    className="border-right border-pricing-team pt-4 text-center footer-color "
                    scope="col"
                  >
                    RabbitMQ
                  </th>
                </tr>
              </thead>
              <tbody className="border-pricing-table kafka-vs-rabbitmq-table">
                <tr>
                  <th className="pl-4" scope="row">
                    Architecture
                  </th>
                  <td>
                    Kafka uses a hybrid approach of messaging queue and publish
                    subscribe
                  </td>
                  <td>RabbitMQ uses a messaging queue approach</td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Scalability
                  </th>
                  <td>
                    Kafka allows distributed partitions across different servers{' '}
                  </td>
                  <td>
                    Increase the number of consumers to the queue to scale out
                    processing across competing consumers
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Message retention
                  </th>
                  <td>
                    Policy-based, with the retention window configurable by the
                    user - for example messages can be stored for two days.
                  </td>
                  <td>
                    Acknowledgement-based: messages are deleted as they are
                    consumed
                  </td>
                </tr>
                <tr>
                  <th className="pl-4" scope="row">
                    Multiple consumers
                  </th>
                  <td>
                    Multiple consumers can subscribe to the same topic, because
                    Kafka allows the same message to be replayed for a given
                    time window.
                  </td>
                  <td>
                    Messages are deleted as they are consumed, so multiple
                    consumers can&apos;t receive the same message.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        <Spacing />

        <div className="container">
          <div className="text-center">
            <StaticImage
              className="img-fluid"
              src="../resources/images/what-is-kafka.png"
              quality={100}
              placeholder="tracedSVG"
              alt="Apache Kafka Architecture"
            />
          </div>
        </div>

        <section>
          <div className="container-1 mobile-margin-top">
            <div className="text-center">
              <h2>Benefits of Apache Kafka for organizations</h2>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <div className="col-md-10 col-12 text-center">
                <p>
                  Apache Kafka is a powerful streaming technology when harnessed
                  in the right way, but it also presents a steep learning curve
                  and productivity difficulties for engineering teams when they
                  start to drive adoption beyond a POC.
                </p>

                <p>
                  This means developers need well-governed
                  <a href="/usecases/apache-kafka-governance/">
                    {' '}
                    self-service
                  </a>{' '}
                  and data access,
                  <a href="/usecases/apache-kafka-data-observability/">
                    {' '}
                    observability
                  </a>
                  ,<a href="/usecases/apache-kafka-security/"> security</a> and
                  building data pipelines
                  <a href="/usecases/kafka-streams/"> (stream processing)</a>.
                </p>
              </div>
            </div>
          </div>
        </section>

        <Spacing />

        <section>
          <div className="container-1">
            <div className="d-flex flex-wrap">
              <div className="col-md-12">
                <h2 className="text-center">Featured Success stories</h2>
                <div className="d-flex flex-wrap">
                  {customerList.map(({ node }) => {
                    return (
                      <div className="col-md-4 col-sm-6 col-12" key={uuidv4()}>
                        <div
                          className="card mt-3 full-customers-container border-0"
                          style={{
                            background: `url(${node.backgroundImage}) center center`,
                          }}
                        >
                          <a
                            className="pl-3 pt-3 pr-3 pb-5  mobile-padding-reset"
                            href={`${node.slug}`}
                          >
                            <img
                              src={node.imageWhiteLogo}
                              className="img-fluid pl-4 pt-4 pr-4 mb-4"
                              alt={node.slug}
                            />
                            <p className="text-white text-center full-customers-text f-22">
                              {node.title}
                            </p>
                          </a>
                          <div className="d-flex justify-content-center">
                            <span className="roll-button customers-read-more-button">
                              <a
                                className="fw-700 text-center f-12 px-3 py-2 rounded bg-white primary-text"
                                href={`${node.slug}`}
                              >
                                {node.slug ===
                                '/videos/babylon-health-dataops-apache-kafka/' ? (
                                  <span data-hover="Watch the full story">
                                    Watch the full story
                                  </span>
                                ) : (
                                  <span data-hover="Read the full story">
                                    Read the full story
                                  </span>
                                )}
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {/* END OF FOR ABOVE ^ */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Spacing />

        {/* <section>
          <div className="container-1 mobile-margin-top mobile-margin-bottom">
            <div className="d-flex flex-wrap">
              <div className="col-md-12">
                <h2 className="text-center">
                  How to build an Apache Kafka Architecture?
                </h2>
                <StaticImage
                  className="img-fluid"
                  src="../resources/images/architecture-diagram.svg"
                  placeholder="tracedSVG"
                  alt="Apache Kafka Architecture"
                />
              </div>
            </div>
          </div>
        </section> */}
        <AboveFooterSection
          text={'Learn Apache Kafka through Lenses'}
          ctaText={'Get started'}
          ctaLink="/start/"
        />
      </Layout>
    </Fragment>
  )
}

export default whatIsApacheKafka
